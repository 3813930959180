.about-part-one {
  position: relative;
  display: flex;
  width: auto;
  flex-direction: column;
}

.about-image {
  min-width: 363px;
  min-height: 421px;
}

.about-image img {
  object-fit: cover;
  height: 72vh;
}

.image-footer {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #151515;
  margin-top: 26px;
  line-height: 22px;
  font-variant: small-caps;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow {
  width: 0;
  position: relative;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 14px solid black;
}

.arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -93px;
  width: 92px;
  height: 2px;
  background-color: black;
  transform: translateY(-50%);
}

.char-content {
  position: relative;
  height: 100%;
}

.s-char {
  margin-top: 23px;
  position: absolute;
  left: -20px;
}

.a-char {
  position: absolute;
  bottom: 0;
  right: 20%;
}

.image-footer p {
  margin-left: 20px;
}

@media (max-height: 800px) {
  .text-svg {
    margin-top: 15px;
  }

  .image-footer {
    margin-top: 10px;
  }
}

@media (max-width: 375px) {
  .image-footer p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .image-footer {
    flex-direction: column;
    align-items: end;
  }

  .about-image {
    min-height: 370px;
    min-width: calc(100vw - 100px);
  }

  .about-image-container {
    display: flex;
    width: 100%;
  }
  .image-footer {
    justify-content: space-around;
    align-items: center;
    width: 15%;
  }
  .image-footer p {
    transform: rotate(270deg);
    text-wrap: nowrap;
    white-space: nowrap;
    margin-left: 0px;
  }
  .arrow {
    transform: rotate(90deg);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 11px solid black;
  }
  .arrow::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -60px;
    width: 52px;
    height: 2px;
    background-color: black;
    transform: translateY(-50%);
  }
  .about-image img {
    width: 100%;
    object-fit: cover;
    height: 10%;
  }

  .s-char,
  .a-char {
    display: none;
  }
}
