.about-container {
  height: 100vh;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  background-color: #f8f6f5;
  position: relative;
}

@media (max-width: 768px) {
  .about-container {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    position: relative;
  }

  .blob-container {
    display: none;
  }
}
