.about-landing-part {
  margin-left: 144px;
  display: flex;

  position: relative;
}

.landing-title-container h1 {
  font-family: rox;
  font-size: 202px;
  text-transform: uppercase;
  color: #151515;
  position: relative;
  writing-mode: vertical-rl;
  transform: scale(-1);
  /* Add some padding to the left to center the text within the container */
}

.landing-title {
  position: relative;
}

.slick-prev {
  display: none !important;
}

.landing-title-container {
  position: relative;
  align-items: center;
  padding-right: 42px;
  font-size: 20px;
  line-height: 100px;
}

.landing-title-container .pc-hr {
  margin-left: 10px;
  height: 250px;
  position: absolute;
  width: 50px;
  bottom: 1060px;
  border: none;
  border-right: 1px solid #151515;
}

.slider-container-wrapper {
  max-width: 20vw;
}

.slider-container {
  height: 100% !important;
  width: 717px;
  max-width: 32vw;
  margin-right: 248px;
}

.slick-list {
  height: 100% !important;
}

.slick-slider {
  display: flex !important;
  gap: 20px !important;
}

.mobile-hr {
  display: none;
}

.landing-image-container {
  margin-left: 102px;
}

.image-one {
  margin-bottom: 16px;
}

/* added */

.landing-image-container {
  position: relative;
  overflow: hidden;
}

.image-one,
.image-two,
.image-three {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
  margin-bottom: 16px;
}

.landing-image-container img {
  width: 35vw;
  height: 72vh;
  object-fit: cover;
}

/* end */

.view-projects {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: scale(-1);
  text-align: end;
  margin-top: 60vh;
  margin-left: 5px;
  color: #151515;
  cursor: pointer;
}

.texts {
  width: 332px;
  margin-top: 32vh;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  color: #151515;
  line-height: 22px;
}

.arrow-landing {
  width: 0;
  position: relative;
  height: 0;
  border-right: 9px solid transparent;
  border-left: 9px solid transparent;
  border-bottom: 14px solid black;
}

.arrow-landing::after {
  content: '';
  position: absolute;
  top: 50%;
  left: -46px;
  width: 92px;
  height: 2px;
  background-color: black;
  transform: rotate(90deg) translateX(64%);
}

.arrow-wrap {
  position: absolute;
  cursor: pointer;
  top: 13px;
}

.expression-two {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  color: #151515;
  font-weight: 700;
  width: 130px;
  margin-right: 82px;
  display: flex;
  align-self: center;
}

@media (max-height: 900px) {
  .landing-title-container h1 {
    font-size: 100px;
  }

  .landing-image-container img {
    width: auto;
    object-fit: cover;
  }

  .view-projects {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .about-landing-part {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-top: 5rem;
  }

  .landing-title-container h1 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 88px;
  }

  .landing-title-container {
    position: relative;
    display: flex;
    gap: 50px;
    align-items: center;
    padding-right: 42px;
    font-size: 20px;
    line-height: 100px;
    width: 45rem;
  }

  .pc-hr {
    display: none;
  }

  .mobile-hr {
    display: block;
    height: 0px;
    width: 100px;
    border: none;
    border-bottom: 1px solid #151515;
  }

  .landing-image-container {
    margin-left: 0;
    margin-top: 34px;
    display: flex;
    width: 100%;
  }

  .image-one {
    margin-bottom: 0;
  }

  .landing-image-container {
    position: relative;
    overflow: hidden;
  }

  .image-one,
  .image-two,
  .image-three {
    display: block;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;
    margin-right: 16px;
    width: 100%;
  }

  .landing-image-container img {
    width: 292px;
    height: 340px !important;
    object-fit: cover;
  }

  .view-projects-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .view-projects {
    writing-mode: horizontal-tb;
    transform: none;
    white-space: nowrap;
    margin-top: 2px;
    margin-left: 34%;
    margin-right: 0;
  }

  .arrow-landing {
    width: 0;
    position: relative;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 11px solid black;
  }

  .arrow-landing::after {
    content: '';
    position: absolute;
    left: -60px;
    width: 52px;
    height: 2px;
    background-color: black;
    transform: rotate(0deg);
  }

  .arrow-wrap {
    right: 0;
    top: 0;
    margin-top: 0.4rem;
  }

  .texts {
    width: 305px;
    margin-left: 20px;
    margin-top: 49px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    color: #151515;
    line-height: 22px;
  }

  .slider-container-wrapper {
    max-width: unset;
    max-height: unset;
  }

  .slider-container {
    width: 100%;
    max-width: unset;
    margin: 0px;
  }

  .slick-track {
    display: flex !important;
    gap: 10px;
  }

  .slick-slider {
    flex-direction: column;
  }

  .lazy-load-image-background.blur.lazy-load-image-loaded {
    width: 100%;
  }
}
