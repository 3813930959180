.footer-container {
  background-color: #f8f6f5;
  display: flex;
  height: 100vh;
  margin-left: 100px;
}

.footer-first-section {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.wanna-be {
  line-height: 0.78;
  margin-top: 50px;
}

.wanna-be p {
  font-family: arial;
  font-size: 162px;
  color: #151515;
}

.wanna-be span {
  font-size: 170px;
  color: #151515;
  font-family: rox;
}

.start {
  margin-left: 0.7em;
}

.something {
  margin-left: 2em;
}

.back-email-container {
  display: flex;
  flex-direction: column;
}

.go-back-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15vh;
}

.footer-circle {
  background-color: #fa5005;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.go-back {
  display: flex;
  flex-direction: column;
}

.go-back p {
  text-transform: uppercase;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  color: #151515;
  font-weight: 700;
}

.arrow-wrapper {
  margin-right: 37px;
  position: relative;
}

.footer-arrow {
  width: 0;
  position: absolute;
  right: 75px;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 14px solid #fa5005;
}

.footer-arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 14px;
  width: 92px;
  height: 2px;
  background-color: #fa5005;
  transform: translateY(-50%);
}

.email-section {
  margin-left: 409px;
}

.footer-text-one {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #151515;
  width: 126px;
  margin-bottom: 6.5vh;
  margin-left: 129px;
}

.email-part {
  position: relative;
  cursor: pointer;
}

.email-part a {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 53px;
  font-weight: 700;
  color: #151515;
  margin-bottom: 29.5px;
  text-decoration: underline;
}

.email-arrow {
  width: 0;
  position: absolute;
  left: 535px;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 14px solid #151515;
}

.email-arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -106px;
  width: 92px;
  height: 2px;
  background-color: #151515;
  transform: translateY(-50%);
}

.footer-second-section {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #151515;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 18vh;
  margin-right: 80px;
}

.footer-text-two {
  position: absolute;
  left: -100px;
}

.footer-text-three {
  margin-top: 24vh;
  margin-left: 125px;
  max-width: 16vw;
  margin-bottom: 43vh;
  display: flex;
  justify-content: flex-end;
}

.credits {
  display: flex;
  justify-content: flex-end;
}

.credits svg {
  width: 13.35px;
  height: 13.35px;
}

.footer-text-four {
  width: 97px;
}

.credits-mobile,
.footer-blob,
.footer-text-two-mobile,
.footer-text-three-mobile {
  display: none;
}

@media (max-width: 1750px) {
  .wanna-be p {
    font-size: 10vw;
  }

  .wanna-be span {
    font-size: 10vw;
  }

  .email-section {
    margin-left: 31vw;
    margin-top: 6vh;
  }
}

@media (max-width: 1500px) {
  .wanna-be p {
    font-size: 7vw;
  }

  .wanna-be span {
    font-size: 7vw;
  }
  .email-section {
    margin-left: 0;
  }

  .email-part a {
    font-size: 4vw;
  }

  .email-arrow {
    left: 30vw;
  }
}

@media (max-height: 900px) {
  .wanna-be p {
    font-size: 90px;
  }

  .wanna-be span {
    font-size: 98px;
  }

  .footer-text-three {
    margin-top: 24vh;
    margin-left: 125px;
    width: 16vw;
    margin-bottom: 23vh;
  }

  .email-part a {
    font-size: 42px;
  }

  .back-email-container {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .footer-container {
    height: auto;
    margin-top: 50px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: 80px;
  }

  .wanna-be {
    margin-top: 0;
  }

  .wanna-be p {
    font-family: arial;
    color: #151515;
    font-size: 62px;
  }

  .wanna-be span {
    font-size: 66px;
    color: #151515;
    font-family: rox;
  }

  .start {
    margin-left: 0.7em;
  }

  .something {
    margin-left: 1rem;
  }

  .back-email-container {
    flex-direction: column;
  }

  .back-email-container > .go-back-wrapper {
    order: 2;
  }

  .back-email-container > .email-section {
    order: 1;
  }

  .email-section {
    margin-left: 0;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }

  .email-section > .footer-text-one {
    order: 2;
  }

  .email-section > .email-part {
    order: 1;
  }

  .footer-text-one {
    font-size: 14px;
    margin-left: 0;
    align-self: flex-end;
    margin-bottom: 0;
    margin-top: 40px;
    margin-right: 30px;
  }

  .email-part {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .email-part a {
    margin-bottom: 29.5px;
    font-size: 24px;
    margin: 0 auto;
  }

  .email-arrow {
    margin-top: 30px;
    left: 325px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 11px solid #151515;
  }

  .email-arrow::before {
    left: -100px;
  }

  .go-back-wrapper {
    margin-top: 15vh;
    justify-content: space-between;
  }

  .go-back {
    display: flex;
    flex-direction: row;
  }

  .go-back p {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  .arrow-wrapper {
    margin-right: 5px;
    position: relative;
  }

  .footer-arrow {
    left: 10px;
    top: -45px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 11px solid #fa5005;
    transform: rotate(90deg);
  }

  .footer-arrow::before {
    content: '';
    position: absolute;
    top: 30%;
    left: 10px;
    width: 72px;
    height: 2px;
    background-color: #fa5005;
    transform: translateX(-10%);
    transform: translateY(-50%);
  }

  .footer-second-section {
    display: none;
  }

  .credits-mobile {
    display: flex;
    margin-left: 0;
    margin-right: 45px;
    align-self: flex-end;
  }

  .credits-mobile svg {
    width: 13.35px;
    height: 13.35px;
  }

  .footer-text-four-mobile {
    font-size: 12px;
    font-family: 'Space Grotesk', sans-serif;
    color: #151515;
    font-weight: 300;
    width: 73px;
  }

  .footer-text-two {
    display: none;
  }

  .footer-text-three {
    display: none;
  }

  .footer-text-two-mobile,
  .footer-text-three-mobile {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #151515;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .footer-text-two-mobile {
    align-self: flex-end;
    margin-right: 18px;
    margin-bottom: 60px;
    margin-top: 30px;
  }

  .footer-text-three-mobile {
    width: 190px;
    margin-left: 0px;
    align-self: flex-end;
    margin-bottom: 1.5rem;
  }

  .footer-blob {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    position: relative;
    height: 200px;
  }

  .footer-first-section {
    width: auto;
  }
}
