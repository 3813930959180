.navbar-wrapper {
  display: flex;
  align-items: center;
  margin-left: 89px;
  position: sticky;
  left: 0px;
  z-index: 99;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  writing-mode: vertical-rl;
  transform: scale(-1);
  padding-right: 10px;
  width: 65px;
  border-left: 1px solid #151515;
  resize: both;
  height: 100%;
  background-color: #f8f6f5;
  z-index: 98;
}

.navbar-list-menu-wrapper {
  display: flex;
  gap: 35px;

  padding-top: 30px;
}

.navbar-list-item-navbar {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #151515;
  list-style: none;
  text-decoration: none;
  cursor: pointer;
  padding-left: 32px;
}

.signature-image {
  transform: rotate(90deg);
  transform-origin: top right;
  width: 200px;
}

.active-link-navbar {
  font-weight: 700;
  border-left: 2px solid black;
}

.menu {
  display: none;
}

.socials-links {
  display: none;
}

@media (max-width: 768px) {
  .navbar-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0px;
    position: sticky;
    left: 0px;
    z-index: 99;
    top: 00px;
  }

  .added-bg {
    background-color: #fa5005;
  }

  .remove-bg {
    background-color: #f8f6f5;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    padding-right: 0px;
    height: 100%;
    border: none;
    border-bottom: 1px solid #151515;
    width: 100%;
    z-index: 98;
    writing-mode: horizontal-tb;
    transform: none;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
  }

  .signature-image {
    transform: rotate(360deg);
    transform-origin: bottom left;
  }

  .navbar-list {
    display: none;
  }

  .menu {
    display: block;
    position: relative;
  }

  .menu-icon {
    transform: scale(1.5);
  }

  .menu-icon {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin-right: 30px;
  }

  .menu-icon div {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 25px;
    height: 12px;
  }

  .menu-icon span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: black;
    border-radius: 1px;
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
  }

  .menu-icon span:first-of-type {
    top: 0;
  }

  .menu-icon span:last-of-type {
    bottom: 0;
  }

  .menu-icon.active span:first-of-type,
  .menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
    transform: rotate(45deg);
    top: 5px;
  }

  .menu-icon.active span:last-of-type,
  .menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
    transform: rotate(-45deg);
    bottom: 5px;
  }

  .navbar-list-menu {
    position: absolute;
    right: 0;
    top: 67px;
    width: 100vw;
    height: 0;
    overflow: hidden;
    transition: 0.5s ease-out;
    display: grid;
    /* grid-template-rows: repeat(3, 1fr); */
    grid-template-rows: 0.5fr 1.5fr 1.5fr;
  }

  .navbar-list-menu-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 0;
    opacity: 0;
    transform: scale(0);
    transform-origin: top right;
    transition:
      transform 0.5s ease-out,
      opacity 0.5s ease-out;
    padding-left: 32px;
    grid-row-start: 2;
  }

  .navbar-list-menu-wrapper a {
    text-transform: uppercase;
    font-family: 'Space Grotesk', sans-serif;
    font-weight: bold;
  }

  .active-ul {
    opacity: 1;
    transform: scale(1);
  }

  .navbar-list-menu.open {
    height: calc(100vh - 83px);
    opacity: 1;
  }
  .active-link-navbar {
    border: none;
    border-bottom: 2px solid black;
  }

  .navbar-list-item-navbar {
    padding-left: 0px;
    font-size: 48px;
  }

  .socials-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 100px;
    grid-row-start: 3;
    align-items: flex-end;
    list-style: none;
  }

  .socials-links a {
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 700;
    text-decoration: none;
    color: #f8f6f5;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
}
