.about-part-two {
  margin-left: 169px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  height: 100vh;
}

.about-part-two-text {
  margin-right: 170px;
  margin-top: 35.5vh;
}

.about-part-two-text p {
  width: 177px;
  height: 131px;
  text-align: left;
}

.first-section {
  margin-top: 97px;
  margin-right: 73px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.first-section p {
  width: 220px;
}

.paragraph-two {
  margin-top: 22.7vh;
  margin-left: 12.8vh;
  margin-bottom: 14.25vh;
}

.skv-char {
  margin-left: 6.8vh;
}

.second-section {
  margin-top: 34.4vh;
}

.lo-char {
  margin-bottom: 26.7vh;
}

.me-char {
  margin-left: 12.2vh;
}

.s-charTwo,
.a-charTwo {
  display: none;
}

.skv-first {
  display: block;
}

.skv-second {
  display: none;
}

@media (max-width: 768px) {
  .about-part-two {
    margin-left: 0;
    position: relative;
    flex-direction: column;
  }

  .s-charTwo,
  .a-charTwo {
    display: block;
    position: absolute;
  }

  .about-part-two-text {
    margin-top: 130px;
    margin-left: 31px;
    margin-right: 0;
  }

  .s-charTwo {
    top: 20px;
    left: -20px;
  }

  .a-charTwo {
    right: 0px;
    top: 60px;
  }

  .first-section {
    margin-top: 30px;
    margin-right: 0;
  }

  .first-section p {
    width: 203px;
  }

  .paragraph-two {
    margin-top: 20px;
    margin-left: 138px;
    margin-bottom: 0;
  }

  .skv-first {
    display: none;
  }

  .skv-second {
    display: block;
  }

  .skv-char {
    margin-left: 0;
    bottom: 0;
    position: absolute;
    left: 30%;
  }

  .second-section {
    margin-top: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 180px;
  }

  .lo-char {
    margin-bottom: 0;
    position: absolute;
    top: -200px;
    right: 0px;
  }

  .me-char {
    margin-left: 0;
    position: absolute;
    top: 0;
    left: -50px;
  }
}
