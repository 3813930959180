.blob {
  position: fixed;
  z-index: 100;
  top: 15px;
  left: 90vw;
  clip-path: polygon(
    22% 0,
    78% 0,
    100% 21%,
    100% 66%,
    60% 100%,
    44% 100%,
    35% 95%,
    32% 70%,
    0 37%,
    0 20%
  );
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
}

.link-wrapper {
  position: relative;
  width: 163px;
  height: 224px;
}

.top-center,
.left-center,
.right-center,
.bottom-center {
  position: absolute;
  color: #282922;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.top-center {
  top: 10px;
  left: 25%;
  cursor: pointer;
}

.left-center {
  top: 45%;
  left: 7px;
  transform: rotate(55deg);
}

.right-center {
  transform: rotate(-90deg);
  top: 40%;
  right: -15px;
}

.transperent {
  bottom: -5px;
  left: 35%;
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 17px;
}

.bottom-center {
  left: 43%;
  bottom: 17px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fa5005;
  position: absolute;
}

.blob:after {
  position: absolute;
  content: '';
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0 8px rgba(201, 201, 201, 0.5);
  border-radius: 50%;
  left: 42.5%;
  bottom: 16px;
}

@media (max-width: 1560px) {
  .blob {
    left: 85vw;
  }
}

@media (max-width: 1100px) {
  .blob {
    left: 75vw;
  }
}

@media (max-width: 768px) {
  .blob {
    position: absolute;
    top: 0%;
    left: 55%;
    margin-right: 18px;
    z-index: 0;
    clip-path: polygon(
      22% 0,
      78% 0,
      100% 21%,
      100% 66%,
      60% 100%,
      44% 100%,
      35% 95%,
      32% 70%,
      0 37%,
      0 20%
    );
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
  }
}
