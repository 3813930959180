.about-part-three {
  display: flex;
  flex-direction: row;
  margin-left: 160.55px;
  margin-right: 225px;
}

.about-part-three-section {
  width: 63vw;
}

.about-part-three h3 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  color: #151515;
  font-weight: 700;
}

.about-me-text-container {
  display: flex;
  flex-direction: column;
}

.about-me-text-container p {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #151515;
  font-weight: 400;
}

.aside-picture-container {
  height: 100vh;
  margin-right: 131px;
  overflow-x: scroll;
  min-width: 399px;
}

.aside-picture-container::-webkit-scrollbar {
  display: none;
}

.aside-picture-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.aside-picture-container h3 {
  text-align: end;
  margin-top: 14px;
  margin-bottom: 15px;
}

.two {
  margin-bottom: 34px;
  width: 399px;
  height: 714px;
  object-fit: cover;
}

.one,
.three {
  width: 399px;
  height: 714px;

  background-image: url('../../assets/images/highlights/Ss.jpeg');
  background-repeat: no-repeat;
}

.one {
  background-size: 210%;
  background-position: left;
}

.three {
  background-size: 190%;
  background-position: right;
}

.pic-three {
  height: 195px;
}

.about-me-text-container {
  text-align: left;
  margin-bottom: 44px;

  margin-top: 8vh;
  justify-content: space-between;
}

.text-wrapper {
  width: 50%;
}

.properties {
  display: flex;
  width: 100%;
}

.properties p {
  color: #716f6f;
  margin-top: 16px;
  font-size: 14px;
}

.point p {
  width: 185px;
  margin-right: 74px;
}
.approach p {
  width: 179px;
  margin-right: 68px;
}
.aim p {
  width: 194px;
}

.kis-logo {
  line-height: 0.6;
  margin-top: 20px;
}

.kis-logo p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 234px;
}

.kis-logo .simp {
  font-family: rox;
  font-size: 245px;
  margin-left: 0.7em;
}

.kis-logo .dot {
  font-family: rox;
  font-size: 300px;
}

.about-part-three-section .lit {
  margin-left: 38vh;
  margin-top: 15.35vh;
}

.about-part-three-section .expression {
  margin-top: 5.5vh;
  margin-left: 50.6vh;
  width: 15vh;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  color: #151515;
  font-weight: 300;
}

.about-part-three-section .expression-two {
  margin-top: 21.12vh;
  margin-left: 85vh;
}

@media (min-width: 768px) and (max-height: 675px) {
  .about-me-text-container {
    flex-direction: row;
    gap: 50px;
    margin-right: 112px;
  }
  .text-wrapper {
    width: 400px;
  }
  .properties {
    gap: 20px;
    flex-direction: row;
    width: auto;
  }
}

@media (max-width: 768px) {
  .about-part-three {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }

  .aside-picture-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 372px;
    margin-right: 0px;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100vw;
  }

  .aside-picture-container h3 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    transform: rotate(270deg);
    text-wrap: nowrap;
    white-space: nowrap;
    height: fit-content;
    width: 10%;
    margin-top: -9em;
  }

  .one {
    height: 100%;
    width: 160%;
    object-fit: cover;
    background-size: cover;
    background-position: right;
  }

  .two {
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .three {
    display: none;
  }

  .about-me-text-container {
    margin-top: 53px;
    margin-left: 21px;
  }

  .about-me-text-container .text-wrapper {
    width: 334px;
    height: auto;
    text-align: left;
    margin-bottom: 44px;
  }

  .properties {
    flex-direction: column;
    width: 100%;
    gap: 33px;
  }

  .approach {
    align-self: flex-end;
    width: 70%;
  }

  .about-part-three-section {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .kis-logo {
    line-height: 1;
  }

  .kis-logo p,
  .kis-logo .simp,
  .kis-logo .dot {
    font-size: 80px;
  }

  .about-part-three-section .lit {
    margin-left: 31px;
    margin-top: 2rem;
  }

  .about-part-three-section .expression {
    margin-top: 23px;
    margin-left: 40vw;
  }

  .about-part-three-section .expression-two {
    margin-top: 84px;
    text-align: end;
    margin-right: 23px;
    margin-left: 0;
  }
}
