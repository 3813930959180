.about-part-four {
  margin-left: 29px;
  display: flex;
  flex-direction: column;
  margin-right: 112px;
}

.principles-wrapper {
  display: flex;
}

.principles-container {
  display: flex;
  margin-top: 14vh;
}

.principles-wrapper-p {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #151515;
  margin-right: 49vh;
  margin-top: 12vh;
  width: 16vh;
}

.principles-container h3 {
  margin-bottom: 38px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: left;
  font-family: 'Space Grotesk', sans-serif;
  color: #151515;
  font-weight: 700;
}

.principles-container p {
  font-size: 14px;

  text-align: left;
  font-family: 'Space Grotesk', sans-serif;
  color: #716f6f;
  font-weight: 400;
}

.res {
  width: 180px;
  margin-right: 96px;
}

.ins {
  width: 189px;
  margin-right: 87px;
}

.qua {
  width: 187px;
  margin-right: 89px;
}

.indi {
  width: 188px;
}

.principles-section {
  display: flex;
}

.four-ps {
  position: relative;
  width: 278px;
  margin-left: 305px;
  margin-top: 29vh;
  margin-left: 28.5vh;
}

.principle-text {
  margin-top: 137.5px;
  width: 143px;
  margin-left: 594.5px;
  font-size: 16px;

  text-align: left;
  font-family: 'Space Grotesk', sans-serif;
  color: #151515;
  font-weight: 300;
}

.four-ps p {
  text-align: left;
  font-family: 'Space Grotesk', sans-serif;
  color: #151515;
  font-weight: 300;
  font-size: 16px;
}

.four-ps-text {
  position: absolute;
  top: 20px;
  right: -150px;
  width: 278px;
}

.ps-char p {
  font-family: arial;
  font-size: 183px;
  color: #151515;
}

.ps-char span {
  font-family: rox;
}

.mobile-section {
  display: none;
}

@media (max-height: 900px) {
  .four-ps {
    margin-top: 10vh;
  }

  .principles-wrapper-p {
    margin-top: 6vh;
    margin-right: 20vh;
  }

  .principles-container {
    margin-top: 7vh;
  }

  .ps-char p {
    font-size: 100px;
  }

  .four-ps-text {
    top: 0px;
  }
}

@media (max-width: 768px) {
  .about-part-four {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-top: 6rem;
  }

  .principles-wrapper {
    flex-direction: column;
  }

  .principles-container {
    flex-direction: column;
    gap: 43px;
    margin-top: 50px;
  }

  .res {
    width: 192px;
    margin-right: 0;
  }

  .ins {
    width: 205px;
    margin-right: 21px;
    align-self: flex-end;
  }

  .qua {
    width: 187px;
    margin-right: 0;
  }

  .indi {
    width: 209px;
    margin-right: 21px;
    align-self: flex-end;
  }

  .principles-wrapper-p {
    display: none;
  }

  .pc-section {
    display: none;
  }

  .mobile-section {
    display: block;
  }

  .principles-section {
    display: flex;
  }

  .principle-text {
    display: none;
  }

  .four-ps {
    width: 310px;
    margin-left: 0;
    margin-top: 0;
    margin-left: 0;
  }

  .four-ps p {
    font-size: 12px;
  }

  .four-ps-text {
    position: absolute;
    top: 30px;
    right: -20px;
    width: auto;
  }

  .ps-char p {
    font-family: arial;
    font-size: 183px;
    color: #151515;
  }

  .ps-char span {
    font-family: rox;
  }
}
