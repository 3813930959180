.swiper {
  width: 11%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-wrapper {
  transition-timing-function: linear;
}

@media (max-width: 768px) {
  .swiper {
    width: 100%;
    height: 10%;
  }
}
