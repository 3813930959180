* {
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: #f8f6f5;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: arial;
  src: url("./assets/fonts/5633-font.ttf");
}

@font-face {
  font-family: rox;
  src: url("./assets/fonts/Roxborough\ CF.ttf");
}

@font-face {
  font-family: roxHeavy;
  src: url("./assets/fonts/roxborough-cf-heavy.ttf");
}

@font-face {
  font-family: caslon;
  src: url("./assets/fonts/Big\ Caslon\ Medium.ttf");
}
